import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React from 'react';
import './index.css';
import App from './app/App';
import {Provider} from "react-redux";
import {configureStore} from "./app/store";
import './app/i18n'
import {init} from "@sentry/react";
import {createRoot} from "react-dom/client";
import {replayIntegration} from "@sentry/browser";

if (process.env.NODE_ENV === 'production') {
    console.debug = () => null
}

if(window._env_ && window._env_.SENTRY_ENV !== 'local') {
    init({
        dsn: "https://fcb240e91a5345dbac8a49259a8c7148@o1289125.ingest.sentry.io/4504717781041152",
        integrations: [
            replayIntegration({
                // Additional Replay configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            })
        ],
        tracesSampleRate: 0.2,
        environment: window._env_.SENTRY_ENV,
        enabled: window._env_.SENTRY_ENV !== 'local',
        replaysSessionSampleRate: 0.005,
        replaysOnErrorSampleRate: 1.0,
        release: "ekultur-login@" + window._env_.REACT_APP_VERSION,
    });
}


const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>
)

