import React from 'react';
import {UsernamePasswordLogin} from "./username-password/UsernamePasswordLogin";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

export const LoginOptions = () => {
    const loginInProgress = useSelector(state => state.auths.inProgress);

    if(loginInProgress) {
        return (
            <Box>
                <CircularProgress
                    sx={{
                        margin: 2
                }}
                />
            </Box>
        )
    }
    return (
        <UsernamePasswordLogin />
    )
}