import {TextFieldForm} from "../../form/TextFieldForm";
import React, {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Form} from "../../form/Form";
import {FormHelperText, Stack} from "@mui/material";
import {FormSubmit} from "../../form/FormSubmit";
import LoginIcon from "@mui/icons-material/Login";
import {useFormState} from "../../form/formContext";
import {ProgressIndicator} from "../../app/ProgressIndicator";

export const UsernamePasswordForm = ({lastLoginFailed, loading, disabled=false, t}) => {
    const [showPassword, setShowPassword] = useState(false)
    const {
        formData,
        initialized
    } = useFormState()

    if(!initialized) {
        return (
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
            >
                <ProgressIndicator />
            </Stack>
        )
    }

    return (
        <Form
            disabled={Boolean(disabled)}
            forceEnable={Boolean(formData.samlDomain)}
        >
            <TextFieldForm
                formKey={'username'}
                label={t('email', 'E-post')}
                ariaLabel={t('emailHelperText', 'Skriv inn e-posten din')}
                fullWidth={true}
                autoFocus={true}
                disabled={Boolean(disabled)}
                autoComplete={"username"}
                sx={{
                    '& input': {
                        textTransform: 'lowercase'
                    }
                }}
            />
            <TextFieldForm
                sx={{
                    mt: 2
                }}
                formKey={'password'}
                label={t('password', 'Passord')}
                ariaLabel={formData.samlDomain ?
                    t('samlHelperText', 'Du blir logget inn med sentral identitetsleverandør knyttet til {{provider}}', {provider: formData.samlDomain})
                    : t('passwordHelperText', 'Skriv inn passordet ditt')}
                fullWidth={true}
                type={showPassword ? 'text' : 'password'}
                autoComplete={"current-password"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={e => e.preventDefault()}
                                tabIndex={-1}
                                size="large">
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                disabled={Boolean(disabled || formData.samlDomain)}
            />
            <FormHelperText error={lastLoginFailed}>
                {lastLoginFailed ? t('wrongCredentials', 'Brukernavn og/eller passord er feil'): ''}
            </FormHelperText>
            <FormSubmit
                loadingButton={true}
                loading={loading}
                forceEnable={Boolean(formData.samlDomain)}
                loadingPosition={"start"}
                disabled={loading}
                variant={"contained"}
                startIcon={<LoginIcon/>}
                sx={{
                    mt: 2,
                    width: "100%"
                }}
            >
                {t('loginButton', 'Logg inn')}
            </FormSubmit>
        </Form>
    )
}